import {Icon } from 'zarm';
import 'zarm/dist/zarm.css';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Bar from '../bar/Bar';

const TabIcon = Icon.createFromIconfont(
  '//lf1-cdn-tos.bytegoofy.com/obj/iconpark/svg_20337_14.627ee457cf7594fbbce6d5e14b8c29ef.js',
);

const Luojia  = () => {
  return (
    <>
       <div>落伽香藤</div>
       <Bar/>
    </>
  );
}


export default Luojia;

// function useState(arg0: string): [any, any] {
//   throw new Error('Function not implemented.');
// }
// function useState(arg0: string): [any, any] {
//   throw new Error('Function not implemented.');
// }

